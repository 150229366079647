<template>
  <div class="w-screen justify-center content-center h-screen bg-white flex flex-wrap">
    <div class="mx-auto w-full max-w-screen-sm md:w-full sm:w-3/4 :lg:p-14 md:p-8 p-6 bg-white">
      <a :href="hasAccount ? redirectLink : 'https://churnkey.co'" target="_blank" class="cursor-pointer">
        <img v-if="hasAccount && branding && branding.brandImage" :src="branding.brandImage" class="w-1/2 mx-auto mb-10 lg:w-1/2 md:w-3/5 sm:w-1/2" />
      </a>
      <p class="text-xl text-brand-medium-brown leading-relaxed mb-8 text-center">
        {{ errorMessage }} If you have questions about your account, please
        <a :href="hasAccount ? redirectLink : 'https://churnkey.co'" target="_blank" class="underline">go to our website</a> and contact customer support.
        Thanks!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
  data() {
    return {};
  },
  computed: {
    errorMessage() {
      const defaultMessage = 'The link you used to reach this page is no longer valid.';
      if (!this.$store.state?.errorMessage) return defaultMessage;
      return this.$store.state?.errorMessage;
    },
    errorCode() {
      return this.$store.state?.errorCode;
    },
    branding() {
      return this.$store.state?.account?.errorContext?.branding;
    },
    hasAccount() {
      return !!this.errorContext?.name;
    },
    redirectLink() {
      let link = this.errorContext?.redirectLink;
      // prepend https:// if not already there
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
  },
  async created() {
    await this.$store.dispatch('FETCH_CONTEXT');
  },
};
</script>

<style lang="scss">
@import '@/assets/style.scss';
</style>
