import Axios from 'axios';

const RESOURCE = '/orgs';

export default {
  /**
   * @param {String} appId is a unqiue org id
   */
  getAccountData() {
    return Axios.get(`${RESOURCE}/dunning/customer`);
  },

  /**
   * @param {String} appId is a unqiue org id
   */
  getSetupIntent() {
    return Axios.get(`${RESOURCE}/dunning/billing`);
  },

  getDemoSetupIntent({ stripeAccountId }) {
    return Axios.post(`${RESOURCE}/dunning/demo-setup-intent`, {
      stripeAccountId,
    });
  },

  /**
   * @param {String} paymentMethodId is the new card id
   */
  chargeBalanceToCard(paymentMethodId) {
    return Axios.post(`${RESOURCE}/dunning/billing`, paymentMethodId);
  },

  /**
   * @param {String} paymentMethodId is the new card id
   */
  reactivate(paymentMethodId) {
    return Axios.post(`${RESOURCE}/dunning/reactivate`, paymentMethodId);
  },

  /**
   * @param {String} domain is a unique domain for the checkout page
   */
  checkOrgDomain(domain) {
    return Axios.get(`${RESOURCE}/dunning/checkout/${domain}`);
  },
};
