import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import EventBus from '@/helpers/event-bus';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Recover.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/update',
      name: 'recover',
      component: () => import('./views/Recover.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/reactivate',
      name: 'reactivate',
      component: () => import('./views/Reactivate.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/v/:shortToken',
      name: 'verify-recover',
      component: () => import('./views/Recover.vue'),
      meta: { requiresAuth: true, shortToken: true },
    },
    {
      path: '/r/:shortToken',
      name: 'verify-reactivate',
      component: () => import('./views/Reactivate.vue'),
      meta: { requiresAuth: true, shortToken: true },
    },
    {
      path: '/demo',
      redirect: '/demo/recover',
    },
    {
      path: '/demo-reactivate',
      redirect: '/demo/reactivate',
    },
    {
      path: '/demo/recover',
      name: 'demo-recover',
      component: () => import('./views/RecoverDemo.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/demo/reactivate',
      name: 'demo-reactivate',
      component: () => import('./views/ReactivationDemo.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('./views/Error.vue'),
      meta: { requiresAuth: false },
    },
    /*
    {
      path: '/test-logos',
      name: 'test-logos',
      component: () => import('./views/TestLogos.vue'),
      meta: { requiresAuth: false },
    },
    */
  ],
});

// perform auth check
router.beforeEach(async (to, from, next) => {
  if (to.meta.shortToken) {
    await store.dispatch('CLEANUP');
    const token = to.params.shortToken;
    axios.defaults.headers.common['x-ck-short-token'] = `${token}`;
    await store.dispatch('SYNC_ACCOUNT');
    return next();
  }
  if (to.meta.requiresAuth === true) {
    if (to.query?.auth?.length > 1) {
      await store.dispatch('CLEANUP');
      // persist jwt for future requests & create auth header
      const token = to.query.auth;
      localStorage.setItem('token', `${token}`);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    if (to.params?.shortToken?.length > 1) {
      // persist jwt for future requests & create auth header
      const token = to.params.shortToken;
      axios.defaults.headers.common['x-ck-short-token'] = `${token}`;
    }
    try {
      await store.dispatch('SYNC_ACCOUNT');
      return next();
    } catch (error) {
      console.log('error', error);
      EventBus.$emit('logIssue', store?.state?.account);
      // Auth error. Remove token and clean up
      await store.dispatch('CLEANUP');
      return next();
    }
  }
  return next();
});

export default router;
