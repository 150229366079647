import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import * as mutations from '@/store/mutation-types';
import * as actions from '@/store/action-types';
import OrgService from '@/api/org.service';
import EventBus from '@/helpers/event-bus';

function handleSubdomainPath(url) {
  const subdomainPath = '.churnkey.co';
  return url.includes(subdomainPath) ? url.split(subdomainPath)[0] : url;
}

// Sync Account Data
const syncAccount = async ({ commit, state }, { force = false } = {}) => {
  try {
    if (state.account && !force) {
      return;
    }
    const account = await OrgService.getAccountData();
    commit(mutations.SET_ACCOUNT, account);
    const { token } = account;
    commit('SET_AUTH_SUCCESS', token);
  } catch (err) {
    EventBus.$emit('logIssue', state.account);
    commit('SET_ERROR', err.response.data);
    commit('RESET_AUTH');
  }
};

// Sync Account Data
const fetchErrorContext = async ({ commit, state }) => {
  try {
    const domain = handleSubdomainPath(location.hostname);
    const context = await OrgService.checkOrgDomain(domain);
    commit(mutations.SET_ACCOUNT, context);
  } catch (err) {
    EventBus.$emit('logIssue', state.account);
    commit(mutations.CLEAR_STATE);
  }
};

// Sync Account Data
const updateCard = async ({ commit, state }) => {
  try {
    if (state.account) {
      // TODO - update conditional and params in updateCard method
      const org = await OrgService.updateCard(state.org, state.card);
    }
  } catch (err) {
    EventBus.$emit('logIssue', state.account);
    console.log('Error updating card', err);
  }
};

const onError = async ({ commit }, { message }) => {
  commit(mutations.RESET_AUTH);
  localStorage.removeItem('token');
  delete axios.defaults.headers.common.Authorization;
};

const cleanup = async ({ commit }) => {
  commit(mutations.RESET_AUTH);
  commit(mutations.CLEAR_STATE);
  commit(mutations.SET_ACCOUNT, null);
  localStorage.removeItem('token');
  delete axios.defaults.headers.common.Authorization;
};

export default {
  [actions.SYNC_ACCOUNT]: syncAccount,
  [actions.FETCH_CONTEXT]: fetchErrorContext,
  [actions.UPDATE_CARD]: updateCard,
  [actions.CLEANUP]: cleanup,
  [actions.ON_ERROR]: onError,
};
