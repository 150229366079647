import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import mutations from '@/store/mutations';
// eslint-disable-next-line import/no-cycle
import actions from '@/store/actions';
import * as getters from '@/store/getters';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['token', 'account'],
    }),
  ],
  state: {
    token: window.localStorage.getItem('token') || '',
    errorCode: null,
    errorMessage: null,
    isError: false,
    account: null,
  },
  actions,
  getters,
  mutations,
});
