import axios from 'axios';
import * as types from './mutation-types';

export default {
  [types.SET_ACCOUNT](state, payload) {
    state.account = payload;
  },
  [types.SET_CUSTOMER](state, payload) {
    state.customer = payload;
  },
  [types.SET_AUTH_SUCCESS](state, token) {
    localStorage.setItem('token', `${token}`);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    state.token = token;
  },
  [types.SET_ERROR](state, payload) {
    const { error, message } = payload;
    state.errorCode = error;
    state.errorMessage = message;
    state.isError = true;
  },
  [types.RESET_AUTH](state) {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common['x-ck-mode'];
    state.token = null;
  },
  [types.CLEAR_STATE](state) {
    state.account = {};
  },
};
